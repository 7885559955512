const isChatBotEnabled = (pathName: string, enabledData: Record<string, { chatBot: boolean }>, locale: string) => {
  const currentPathSegments = pathName.replace(`/${locale}/`, '/')?.split('/').slice(1) || [];
  const chatBotConfig: Record<string, { chatBot: boolean }> = enabledData || {};

  const pathMatch = pathName && chatBotConfig[pathName];
  if (pathMatch) {
    return pathMatch.chatBot;
  }
  const isMatchingPath = ([path]: [string, { chatBot: boolean }]) => {
    const enabledPathSegments = path.split('/').slice(1);
    const isCurrentPath = currentPathSegments.length > 0 && currentPathSegments[0] === enabledPathSegments[0];

    if (
      isCurrentPath &&
      currentPathSegments[currentPathSegments.length - 1] === enabledPathSegments[enabledPathSegments.length - 1]
    ) {
      return true;
    }

    return (
      isCurrentPath &&
      enabledPathSegments[enabledPathSegments.length - 1] === '[]' &&
      currentPathSegments.length === enabledPathSegments.length
    );
  };

  const chatBotArr = Object.entries(chatBotConfig)?.filter(isMatchingPath);

  return chatBotArr.length > 0 ? chatBotArr[0][1].chatBot : true;
};

export default isChatBotEnabled;
