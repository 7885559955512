import { Login } from '@commerce-identity-webcomponents/ui';
import { useNextgenRouter } from '@nextgen-web-framework/all';
import { ConfigService } from '@nextgen-web-framework/core';
import { getLoginPageData, getInvalidateTime, getSignUpUrl } from '../../../utils/common';
import withNextGenISR from '../../../utils/withNextGenISR';
import styles from './Login.module.scss';
import { PageCategory } from 'types/common';
import { staticLocalePaths } from 'utils/staticLocalePaths';
import SEOComponent from 'components/SEOComponent/SEOComponent';
import AdaChatbot from 'components/AdaChatbot/AdaChatbot';

const LoginComp = ({ bannerImgUrl }: { bannerImgUrl: string }) => {
  const tuples = ApiConfigService.getTuples();
  const router = useNextgenRouter();

  let postAuthUrl = '';
  if (typeof window !== 'undefined') {
    postAuthUrl = btoa(
      JSON.stringify({ redirectUri: window.location.href, headers: tuples, amwayLang: router.params?.locale }),
    );
  }
  const configs = ConfigService.getConfigs();
  const allowSelfLedRegistration = configs.NEXT_PUBLIC_IDENTITY_ALLOW_SELF_LED_REGISTRATION as boolean;

  return (
    <>
      <div className={styles['login-page-container']} data-testid="login-page-testid">
        <SEOComponent />
        <Login
          banner_img_src={bannerImgUrl}
          signupLinkClickHandler={() => router.push(getSignUpUrl())}
          state={postAuthUrl}
          linkage={false}
          forgotPasswordLinkClickHandler={() => router.push('/forgot-password')}
          forgotUsernameLinkClickHandler={() => router.push('/forgot-username')}
          allowSelfLedRegistration={allowSelfLedRegistration}
        />
      </div>
      {configs.NEXT_ADA_DATA_HANDLE && <AdaChatbot />}
    </>
  );
};

export const getStaticPaths = staticLocalePaths;

export const getStaticProps = withNextGenISR(async (context) => {
  const { props } = await getLoginPageData(context.params?.locale as string);
  return {
    props: {
      ...props,
    },
    revalidate: getInvalidateTime(PageCategory.DEFERRED),
  };
});

export default LoginComp;
