import { NextgenImage, useSession, useNextgenRouter } from '@nextgen-web-framework/all';
import { oidcApi } from '@commerce-webcomponents/sdk';
import { Logger, ConfigService } from '@nextgen-web-framework/core';
import { useEffect, useState } from 'react';
import styles from './AdaChatbot.module.scss';
import { dragElement } from 'utils/common';
import logObject from 'utils/logObject';
import { useLayoutUtility } from 'components/LayoutUtilityProvider/LayoutUtilityProvider';
import isChatBotEnabled from 'utils/isChatBotEnabled';

const logger = Logger.getInstance(logObject('AdaChatbot.tsx', 'Chatbot'));

const AdaChatbot = () => {
  const config = ConfigService.getConfigs();
  const { NEXT_ASSETS_BASE_PATH, NEXT_ADA_DATA_HANDLE } = config;
  const { isStickyHeaderAvailable, stickyBottomHeaderHeight, isContentScrolled } = useLayoutUtility();
  const chatbotIconDimensions = 56;
  const { isAnonymous } = useSession();
  const router = useNextgenRouter();
  const [chatBotEnable, setChatBotEnable] = useState(true);
  useEffect(() => {
    const customAdaButton = document.getElementById(styles['ada-custom-buttom']);
    customAdaButton && dragElement(customAdaButton, onAdaButtonClick);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--stickyBottomBarHeight', `${stickyBottomHeaderHeight ?? 0}px`);
  }, [stickyBottomHeaderHeight]);

  useEffect(() => {
    if (router.params?.locale) {
      window.adaSettings = {
        ...window.adaSettings,
        language: router.params?.locale as string,
      };
    }
  }, [router.params?.locale]);

  const fetchSession = async () => {
    try {
      const res = await oidcApi.getSession();
      // Retrieve CBS ID here
      return res.sessionId;
    } catch (err) {
      logger.error(err, 'Error Occured');
    }
    return null;
  };
  const onAdaButtonClick = async () => {
    if (window.adaEmbed) {
      window.adaEmbed.toggle();
    }
    let cbsId = null;
    if (!isAnonymous()) {
      cbsId = await fetchSession();
    }
    window?.adaEmbed?.setSensitiveMetaFields({
      jwt_token: cbsId,
    });
  };

  useEffect(() => {
    if (NEXT_ADA_DATA_HANDLE && router.pathname) {
      const locale = router.params?.locale;
      const matchedPath: boolean = isChatBotEnabled(
        router.pathname,
        config?.chatBotEnabledData as Record<string, { chatBot: boolean }>,
        locale as string,
      );
      setChatBotEnable(matchedPath);
    }
  }, [router.pathname]);

  return (
    <>
      {chatBotEnable ? (
        <div className={`${styles['chatbot-container']} ${isContentScrolled ? styles['chatbot-on-scroll'] : ''}`}>
          <div
            id={`${styles['ada-custom-buttom']}`}
            className={`${isStickyHeaderAvailable ? styles['is-sticky-bottom'] : styles['ada-bottom']}`}
            data-testid={'custom-ada-button-testid'}
          >
            <NextgenImage
              src={`${NEXT_ASSETS_BASE_PATH}/icons/LiveChatBubble.svg`}
              width={chatbotIconDimensions}
              height={chatbotIconDimensions}
              fill={false}
              alt={'Chat with bot'}
            />{' '}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default AdaChatbot;
